.Footer {
  position: relative;
  z-index: 999;
  border-top: 1px solid #efefef;
  padding: 1rem 0;
}
.Footer .nav_wrap ul {
  height: 40px;
  padding: 0;
}
.Footer .nav_wrap ul li {
  list-style: none;
  margin-top: 10px;
  display: inline-block;
}
.Footer .nav_wrap ul li a {
  font-size: 1.8em;
  text-decoration: none;
  color: #a9a9a9;
  display: inline-block;
  font-weight: 100;
  padding: 1rem 20px;
}
.Footer .nav_wrap ul li a:hover {
  border-bottom: 2px solid #f47d2b;
}
.Footer .copy_wrap {
  text-align: right;
  margin-top: 15px;

}
.Footer .copy_wrap h3 {
  height: 40px;
  margin-right: 40px;
  display: inline-block;
}
/*.Footer .copy_wrap label {*/
  /*margin-right: 10%;*/
/*}*/
.Footer .copy_wrap select {
  color: grey;
  font-size: 1.4em;
  font-weight: 500;
  line-height: 1.4em;
}
@media screen and (max-width: 480px), screen and (max-device-width: 480px) {
  .Footer .nav_wrap {
    text-align: center;
  }
  .Footer .nav_wrap ul {
    padding: 0;
  }
  .Footer .nav_wrap ul li a {
    padding: 0 11px 0;
  }
  .Footer .copy_wrap {
    text-align: center;
    background-color: darkgray;
    color: white;
  }
  .Footer .copy_wrap select {
    background-color: transparent !important;
    border: none;
    color: white;
    margin-top: 10px;
  }
}
