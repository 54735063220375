.AvatarAni .mouseEvent_wrap {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: inline-block;
  z-index: 7;
}
.AvatarAni .avatar_wrap {
  width: 215px;
  height: 215px;
  border-radius: 150px;
  overflow: hidden;
  border: 2px solid grey;
  position: relative;
  z-index: 3;
}
.AvatarAni .avatar_wrap .face_wrap {
  position: absolute;
}
.AvatarAni .avatar_wrap .face_wrap .avatar {
  background: transparent url(/images/home/sam_avatar_ani.svg) 0 0/215px 430px no-repeat;
  width: 215px;
  height: 215px;
  position: absolute;
  z-index: 5;
}
.AvatarAni .avatar_wrap .face_wrap .eye-l {
  background: transparent url(/images/home/eye.svg) 0 0 no-repeat;
  width: 27px;
  height: 27px;
  position: absolute;
  top: 97px;
  left: 65px;
  z-index: 4;
}
.AvatarAni .avatar_wrap .face_wrap .eye-r {
  background: transparent url(/images/home/eye.svg) 0 0 no-repeat;
  width: 27px;
  height: 27px;
  position: absolute;
  top: 97px;
  left: 120px;
  z-index: 4;
}
.AvatarAni .avatar_wrap .face_wrap .bubble {
  background: transparent url(/images/home/sam_bubble_ani.svg) 0 0 no-repeat;
  width: 27px;
  height: 27px;
  position: absolute;
  top: 150px;
  left: 109px;
  transform-origin: left top;
  opacity: 0.6;
  transform: scale(0);
  z-index: 6;
}
.AvatarAni .avatar_wrap .body_wrap .body {
  background: transparent url(/images/home/sam_body_ani.svg) 0 0 no-repeat;
  width: 47px;
  height: 47px;
  position: absolute;
  top: 176px;
  left: 82px;
  z-index: 4;
}
