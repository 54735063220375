.about_wrap {
  background-color: white;
  margin-top: 40px;
  display: inline-block;
  padding: 30px;
}
.about_wrap img {
  width: 100%;
}
@media screen and (max-width: 480px), screen and (max-device-width: 480px) {
  .pull-right {
    width: 100%;
  }
  h1 {
    margin-top: 30px;
  }
}

