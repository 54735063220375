.PageHeader {
  background-color: var(--darkGrey);
  color: white;
  padding: 15rem 0;
}

.PageHeader-large {
  padding: 20rem 0;
}

.PageHeader--Title {
  font-size: 6rem;
  margin-bottom: 0;
}

.PageHeader--Subtitle {
  font-weight: 400;
  font-size: 1.8rem;
}
