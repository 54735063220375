.Nav {
  background-color: rgba(255, 255, 255, .8);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  box-shadow: 0 0 0 transparent;
  transition: box-shadow 0.3s ease-in-out;
}
.Nav .main_header {
  min-height: 60px;
}
.Nav .main_header .logo_wrap .logo {
  background: transparent url(/images/sam-logo.svg) 0 0 no-repeat;
  width: 100%;
  height: 30px;
  display: inline-block;
  margin-top: 16px;
  margin-right: 10px;
  cursor: pointer;
}
.Nav .main_header .nav_wrap {
  position: relative;
  height: 100%;
}
.Nav .main_header .nav_wrap ul {
  float: right;
  margin-right: 5%;
  border: none;
}
.Nav .main_header .nav_wrap ul li {
  list-style: none;
  display: inline-block;
  margin: auto 5px;
  line-height: 2;
  min-height: 52px;
}
.Nav .main_header .dropdown-header {
  font-size: 16px;
  font-weight: 100;
}
.Nav .main_header .nav_wrap ul li a {
  border-color: transparent;
  text-decoration: none;
  padding: 10px 15px 0px;
  display: inline-block;
  color: darkgrey;
  font-size: 18px;
  font-weight: 100;
}
.Nav .main_header .nav_wrap ul li a.active, .Nav .main_header .nav_wrap ul li a:hover {
  border-bottom: 2px solid #f47d2b;
}
/*.Nav .main_header .nav_wrap ul li a.nav-link.dropdown-toggle {*/
  /*border-color: transparent;*/
/*}*/
.Nav .main_header .nav_wrap ul li.dropdown.active a.nav-link.dropdown-toggle, .Nav .main_header .nav_wrap ul li.dropdown:hover a.nav-link.dropdown-toggle {
  border-bottom: 2px solid #f47d2b;
  background-color: white;
}
.Nav .main_header .nav_wrap ul .dropdown-item a:active, .Nav .main_header .nav_wrap ul .dropdown-item a:hover, .Nav .main_header .nav_wrap ul .dropdown-item:active {
  background-color: transparent;
  border: none;
}
.Nav .main_header .nav_wrap ul.dropdown a {
  font-size: 18px;
  font-weight: 100;
}
.Nav .main_header .nav_wrap li.dropdown .dropdown-menu button {
  margin: 1rem 0;
}
@media screen and (min-width: 768px) and (max-width: 1024px), screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .Nav .main_header .nav_wrap ul li a {
    padding: 10px 10px 0 !important;
  }
}
/* ==== Portrait ==== */
@media screen and (max-width: 480px), screen and (max-device-width: 480px) {
  .Nav .main_header .logo_wrap .logo {
    background-position: center;
  }
  .Nav .main_header .nav_wrap ul {
    float: none;
    text-align: center;
    padding: 0;
  }
  .Nav .main_header .nav_wrap ul li {
    margin: auto 0;
  }
  .Nav .main_header .nav_wrap ul li a {
    padding: 20px 5px 0;
  }
}

