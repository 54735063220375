.Contact {
  text-decoration: none;
  margin: 5rem 2rem 2rem;
  background: white;
}
.Contact .info-wrap { background-color: var(--background); height: 100%; position: relative; background-size: cover; background-repeat: no-repeat; background-position: center; padding-bottom: 5rem; }
.Contact .info-wrap .mask{ background-color: #efefef; position: absolute; z-index:9; top: 0; left: 0; width: 100%; height: 100%; }
.Contact .info-wrap .Content{ position: relative; z-index: 10; padding: 3rem 3rem 0 3rem; font-size: 1.6em; }
/*.Contact .info-wrap a { display: block; margin-bottom: 10px; width: 100%; }*/

.Contact .Content h1 { font-size: 2em; margin-bottom: 4rem; }
.Contact .Content p { margin-bottom: 2rem; }
.Contact .Content p,
.Contact .Content li { font-size: 1em; }
.Contact .Content { color: #8a8a8a; }

.Contact .email { position: relative; z-index: 10; font-size: 1.6em; color: #fe9d5c; margin-left: 3rem; }

@media (max-width: 768px) {
  .Contact .info-wrap{ width: 100%; min-height: 400px; }
  .Contact .Content { width: 100%; }
}
