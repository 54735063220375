/*Reset Style*/
div, applet, acronym, big, s, strike, tt, u, center, canvas, embed, figcaption, hgroup, output {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-weight: normal;
  vertical-align: baseline;
  background: transparent;
}
/* HTML5 display-role reset for older browsers */
figcaption, hgroup, menu {
  display: block;
}
ol {
  list-style: none;
}
/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}
/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}
del {
  text-decoration: line-through;
}
abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}
/*footer select, .safari select, input[type=text], input[type=tel], input[type=password], input[type=email], textarea, button {*/
  /*-webkit-appearance: none;*/
  /*-moz-appearance: none;*/
  /*appearance: none;*/
  /*outline: 0 none;*/
  /*border: 1px solid #fff;*/
  /*padding: 10px;*/
  /*margin: 0;*/
  /*-moz-border-radius: none;*/
  /*-khtml-border-radius: none;*/
  /*-webkit-border-radius: none;*/
  /*border-radius: none;*/
  /*-webkit-box-shadow: none;*/
  /*-moz-box-shadow: none;*/
  /*-ms-box-shadow: none;*/
  /*-o-box-shadow: none;*/
  /*box-shadow: none;*/
/*}*/
select {
  outline: 0 none;
  border: 1px solid #fff;
  padding: 10px;
  margin: 0;
  -moz-border-radius: none;
  -khtml-border-radius: none;
  -webkit-border-radius: none;
  border-radius: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}
/* apply a natural box layout model to all elements (make padding not expand divs) */
*, *:before, *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}
body {
  font-size: 1em;
  line-height: 1em;
  background: #fff;
}
a, a:visited {
  text-decoration: none;
}
a.disabled-link {
  pointer-events: none;
}
/* === Header Styles ems ===*/
h1 {font-size: 1.8em;font-weight: 700;letter-spacing: 1px;}
h2 {font-size: 1.6em;font-weight: 700;letter-spacing: 1px;}
h3 {font-size: 1.4em;font-weight: 700;letter-spacing: 1px;}
h4 {font-size: 1.2em;font-weight: 700;letter-spacing: 0.5px;}
h5 {font-size: 1em;font-weight: 700;letter-spacing: 1px;}
h6 {font-size: 0.9em;font-weight: 700;letter-spacing: 1px;}

.divider {
  width: 100%;
  height: 1px;
  background-color: #dedede;
}
.browsehappy {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 7px 0;
  text-align: center;
  margin: 0;
}

/* === Other Language Fonts ===*/
.en, body {font-family: "Open Sans", Helvetica, Arial, sans-serif;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;font-weight: 300;line-height: 1;}
.en select, .en option, .en button, .en pre, body select, body option, body button, body pre {font-family: "Open Sans", Helvetica, Arial, sans-serif;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;line-height: 1;font-weight: 700;}

.zh {font-family: "NotoSansCJKtc", Arial, Helvetica, sans-serif;font-weight: 300;line-height: 1;}
.zh select, .zh option, .zh button, .zh pre {font-family: "NotoSansCJKtc", Arial, Helvetica, sans-serif;line-height: 1;font-weight: 700;}

option {
  font-weight: 400;
}
button {
  border: none;
}
button.hidden {
  visibility: hidden;
}
button.visible {
  visibility: visible;
}
input {
  color: #fff;
  outline: none;
}
input::-webkit-input-placeholder {
  color: #fff;
}
input:-moz-placeholder {
  color: #fff;
  opacity: 1;
}
input::-moz-placeholder {
  color: #fff;
  opacity: 1;
}
input:-ms-input-placeholder {
  color: #fff;
}
/*Text*/
.light {font-weight: 100;}
.standard {font-weight: 300;}
.heavy {font-weight: 700;}
.f18 {font-size: 1.8em;}
.f16 {font-size: 1.6em;}
.f15 {font-size: 1.5em;}
.f14 {font-size: 1.4em;}
.f13 {font-size: 1.3em;}
.f12 {font-size: 1.2em;}
.f11 {font-size: 1.1em;}
.f10 {font-size: 1em;}
.f24 {font-size: 2.4em;}
.f22 {font-size: 2.2em;}
.f28 {font-size: 2.8em;}
.f30 {font-size: 3em;}
.f36 {font-size: 3.6em;}
.f48 {font-size: 4.8em;}

p {
  font-size: 1.6em;
  font-weight: 100;
  line-height: 2em;
}
ul li {
  font-weight: 100;
  line-height: 2em;
  list-style: disc;
  margin: 1.5rem 0;
}
ul li.no-list {
  list-style: none;
}

.txt_w {color: #fff;}
.txt_g {color: #dedede;}
.txt_gr {color: #27d17e;}
.txt_dg {color: #7d7d7d;}
.txt_l {color: #b2b2b2;}
.txt_b {color: #222;}
.txt_bl {color: #33c6fe;}
.txt_r {color: #ff5722;}
.txt_app {color: #fe9d5c;}

.txt_space {margin: 0 0 15px;display: inline-block;}
.txt_highlight {width: 30px;height: 30px;-moz-border-radius: 15px;-webkit-border-radius: 15px;border-radius: 15px;-khtml-border-radius: 15px;background-color: #fff;color: #222;padding: 3px 0px;text-align: center;
}
.txt_center {text-align: center;}
.txt_right {text-align: right;}
.txt_left {text-align: left;}
.txt_inline {display: inline;}
.txt_valign_super {vertical-align: super;}
.txt_uppercase {text-transform: uppercase;}
.txt_break {word-break: break-word;}

.float_l {float: left;}
.float_r {float: right;}

.bold {font-weight: 700;}
.semi_bold {font-weight: 600;}
.regular {font-weight: 400;}
.light {font-weight: 300;}


@-moz-document url-prefix() {
  .select_wrap {
    overflow: hidden;
    display: block;
    margin: 0 0 0 0;
  }
  .select_wrap select {
    width: 120%;
    margin-top: -5px;
  }
}

select::-ms-expand {
  display: none;
}

.required_form {
  position: relative;
  display: inline-block;
}
.required_form::after {
  content: '*';
  color: #ff5722;
  font-size: 1em;
  font-weight: 400;
  line-height: 1em;
  position: absolute;
  right: -7px;
  top: 8%;
}

/*style when input disabled*/
input[disabled='disabled'] {
  background-color: #f6f6f6;
}
/*style for select background color*/
select {
  background-color: #fff !important;
}
.truncate {
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
}
.downArrow {
  display: inline-block;
  width: 0;
  height: 0;
  border-top: 6px solid #fe9d5c;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  color: #fff;
  margin-bottom: 2px;
  cursor: pointer;
}
/* =========================== * * Member Page View * * ========================= */
.sprite, .sprite_a::after, .sprite_b::before {
  background-image: url(/images/sprite.svg);
  background-repeat: no-repeat;
  background-size: 170px 400px;
}
.close_x_white {
  position: absolute;
  top: 22px;
  right: 10px;
  width: 10px;
  height: 10px;
  background-size: 10px;
  background-position: -55px -12px;
  padding: 10px;
  cursor: pointer;
}
.icon {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 12px;
  top: 0;
  z-index: 10;
}
.icon.close_icon {
  background-position: 0 0;
}
.icon.close_icon span {
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 11;
  opacity: 0;
  transition: opacity 0.3s ease-in;
}
.icon.close_iconhover {
  opacity: 0;
}
.icon.close_icon:hover span {
  background-position: -26px 0;
  opacity: 1;
}
.loader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  text-align: center;
  background-color: rgba(255, 255, 255, .9);
  z-index: 99;
}
.loader span {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 145px 3px 0;
  background: rgba(252, 52, 102, 0.25);
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -khtml-border-radius: 5px;
  transform: translateY(0);
  -moz-transform: translateY(0);
  -webkit-transform: translateY(0);
  animation: wave 2s infinite ease-in-out;
  -moz-animation: wave 2s infinite ease-in-out;
  -webkit-animation: wave 2s infinite ease-in-out;
}
@keyframes wave {
  0%, 60%, 100% {
    background: rgba(252, 52, 102, 0.25);
    transform: translateY(0);
    -moz-transform: translateY(0);
  }
  20% {
    background: rgba(252, 52, 102, 0.75);
    transform: translateY(13px);
    -moz-transform: translateY(13px);
  }
  40% {
    background: rgba(252, 52, 102, 0.75);
    transform: translateY(-13px);
    -moz-transform: translateY(-13px);
  }
}
@-webkit-keyframes wave {
  0%, 60%, 100% {
    background: rgba(252, 52, 102, 0.25);
    transform: translateY(0);
    -webkit-transform: translateY(0);
  }
  20% {
    background: rgba(252, 52, 102, 0.75);
    transform: translateY(13px);
    -webkit-transform: translateY(13px);
  }
  40% {
    background: rgba(252, 52, 102, 0.75);
    transform: translateY(-13px);
    -webkit-transform: translateY(-13px);
  }
}
.loader span:nth-child(1) {
  animation-delay: 0s;
  -moz-animation-delay: 0s;
  -webkit-animation-delay: 0s;
}
.loader span:nth-child(2) {
  animation-delay: 0.1s;
  -moz-animation-delay: 0.1s;
  -webkit-animation-delay: 0.1s;
}
.loader span:nth-child(3) {
  animation-delay: 0.2s;
  -moz-animation-delay: 0.2s;
  -webkit-animation-delay: 0.2s;
}
.loader span:nth-child(4) {
  animation-delay: 0.3s;
  -moz-animation-delay: 0.3s;
  -webkit-animation-delay: 0.3s;
}
.loader span:nth-child(5) {
  animation-delay: 0.4s;
  -moz-animation-delay: 0.4s;
  -webkit-animation-delay: 0.4s;
}
.divider-40 {
  margin: 40px auto;
  display: inline-block;
  width: 100%;
}
.divider-20 {
  margin: 20px auto;
  display: inline-block;
  width: 100%;
}
.divider-10 {
  margin: 10px auto;
  display: inline-block;
  width: 100%;
}
.secBtn_wrap .btn:focus, .btn_wrap .btn:focus {
  outline: 0;
}
.secBtn_wrap .btn.btn_b, .btn_wrap .btn.btn_b {
  background-color: #33c6fe;
  color: #fff;
}
.secBtn_wrap .btn.btn_b:hover, .btn_wrap .btn.btn_b:hover {
  background-color: #074cc7;
}
.secBtn_wrap .btn.btn_app, .btn_wrap .btn.btn_app {
  background-color: #fe9d5c;
  color: #fff;
  border-color: #fe9d5c;
}
.secBtn_wrap .btn.btn_app:hover, .btn_wrap .btn.btn_app:hover {
  background-color: #f9862d;
}
.secBtn_wrap .btn.btn_w, .btn_wrap .btn.btn_w {
  background-color: #fff;
  color: #7d7d7d;
}
.secBtn_wrap .btn.btn_w:hover, .btn_wrap .btn.btn_w:hover {
  background-color: #fe9d5c;
  color: #fff;
}
.secBtn_wrap .btn.btn_dg, .btn_wrap .btn.btn_dg {
  background-color: #7d7d7d;
  color: #fff;
}
.secBtn_wrap .btn.btn_dg:hover, .btn_wrap .btn.btn_dg:hover {
  background-color: #585858;
}
.secBtn_wrap .btn.btn_lg, .btn_wrap .btn.btn_lg {
  background-color: #f6f6f6;
  color: #33c6fe;
}
.secBtn_wrap .btn.btn_lg:hover, .btn_wrap .btn.btn_lg:hover {
  background-color: #dedede;
}
.secBtn_wrap .btn.btn_g, .btn_wrap .btn.btn_g {
  background-color: #dedede;
  color: #fff;
}
.secBtn_wrap .btn.btn_g:hover, .btn_wrap .btn.btn_g:hover {
  background-color: #7d7d7d;
}
.secBtn_wrap .btn.btn_r, .btn_wrap .btn.btn_r {
  background-color: #ff5722;
  color: #fff;
  border-color: #ff5722;
}
.secBtn_wrap .btn.btn_r:hover, .btn_wrap .btn.btn_r:hover {
  background-color: #de3d0a;
  border-color: #de3d0a;
}
.secBtn_wrap .btn.btn_hallow, .btn_wrap .btn.btn_hallow {
  background-color: transparent;
  color: #8692a5;
  border: 1px solid #8692a5;
}
.secBtn_wrap .btn.btn_hallow:hover, .btn_wrap .btn.btn_hallow:hover {
  background-color: #fe9d5c;
  color: #fff;
  border: 1px solid #fe9d5c;
}
.secBtn_wrap .btn.btn_translucent, .btn_wrap .btn.btn_translucent {
  background-color: rgba(255, 255, 255, .95);
  color: #33c6fe;
}
.secBtn_wrap .btn.btn_translucent:hover, .btn_wrap .btn.btn_translucent:hover {
  background-color: #fff;
}
.secBtn_wrap .btn.btn_border, .btn_wrap .btn.btn_border {
  border: 1px solid #dedede;
}
.secBtn_wrap .btn.btn_border_b, .btn_wrap .btn.btn_border_b {
  border: 1px solid #fe9d5c;
}
.secBtn_wrap .btn:disabled, .btn_wrap .btn:disabled {
  background-color: #7d7d7d;
  color: #fff !important;
  cursor: auto;
  border-color: #7d7d7d;
}
.secBtn_wrap .btn:disabled:hover, .btn_wrap .btn:disabled:hover {
  background-color: #7d7d7d;
}
.secBtn_wrap .btn.btn_next, .btn_wrap .btn.btn_next {
  margin-left: 5px;
}
.box_shadow {
  -webkit-box-shadow: 0 6px 14px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 6px 14px rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 0 6px 14px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0 6px 14px rgba(0, 0, 0, 0.2);
  box-shadow: 0 6px 14px rgba(0, 0, 0, 0.2);
}
.secBtn_wrap {
}
.secBtn_wrap .btn {
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  -khtml-border-radius: 20px;
  font-size: 1.4em;
  font-weight: 400;
  line-height: 1.1em;
  padding: 5px 18px;
  -moz-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  height: 26px;
  background-color: transparent;
  color: #7d7d7d;
  border: 1px solid #7d7d7d;
  vertical-align: baseline;
  margin-left: 5px;
}
.secBtn_wrap .btn:hover {
  background-color: #fe9d5c;
  color: #fff;
  border-color: #fe9d5c;
}
.secBtn_wrap .btn a {
  color: #7d7d7d;
  text-decoration: none;
  -moz-transition: color 0.3s ease-in;
  -o-transition: color 0.3s ease-in;
  -webkit-transition: color 0.3s ease-in;
  transition: color 0.3s ease-in;
}
.secBtn_wrap .btn a:hover {
  color: #fff;
}
.btn_wrap .btn {
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -khtml-border-radius: 5px;
  font-size: 1.8em;
  font-weight: 400;
  line-height: 1.1em;
  padding: 7px 25px;
  height: 35px;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.btn_wrap .btn.ng-enter {
  opacity: 0;
}
.btn_wrap .btn.ng-enter-active {
  opacity: 1;
}
.btn_wrap .btn.ng-leave {
  opacity: 1;
}
.btn_wrap .btn.ng-leave-active {
  opacity: 0;
}
.btn_wrap.bk_wrap {
  position: absolute;
}
.btn_wrap.bk_wrap .bk_btn {
  font-size: 1.3em;
  background-color: rgba(255, 255, 255, 0.7);
  -moz-border-radius: 0 40px 40px 0;
  -webkit-border-radius: 0 40px 40px 0;
  border-radius: 0 40px 40px 0;
  -khtml-border-radius: 0 40px 40px 0;
  border: 1px solid #dedede;
  position: relative;
}
.btn_wrap.bk_wrap .bk_btn:before {
  content: '\2039';
  position: absolute;
  left: 10px;
  top: 7px;
  font-size: 2.8em;
  font-weight: 100;
}
.btn_wrap.bk_wrap .bk_btn:hover {
  background-color: #fff;
}
.space_top {
  margin-top: 20px;
}
.space_bottom {
  margin-bottom: 20px;
}
.space_right {
  margin-right: 20px;
}
.space_left {
  margin-left: 20px;
}
input[type=text].text_input, input[type=password].text_input, input[type=email].text_input, .speaker_bio, .intl-tel-input input, textarea.text_area, .search_input input[type=search] {
  font-size: 1.8em;
  font-weight: 300;
  line-height: 1.5em;
  padding: 0px 20px 0 0;
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #dedede;
  color: #fe9d5c;
  display: block;
  width: 100%;
  margin: 0 auto;
  text-align: left;
}
input[type=text].text_input:-webkit-autofill, input[type=password].text_input:-webkit-autofill, input[type=email].text_input:-webkit-autofill, .speaker_bio:-webkit-autofill, .intl-tel-input input:-webkit-autofill, textarea.text_area:-webkit-autofill, input[type=text].text_input:-webkit-autofill:hover, input[type=password].text_input:-webkit-autofill:hover, input[type=email].text_input:-webkit-autofill:hover, .speaker_bio:-webkit-autofill:hover, .intl-tel-input input:-webkit-autofill:hover, textarea.text_area:-webkit-autofill:hover, input[type=text].text_input:-webkit-autofill:focus, input[type=password].text_input:-webkit-autofill:focus, input[type=email].text_input:-webkit-autofill:focus, .speaker_bio:-webkit-autofill:focus, .intl-tel-input input:-webkit-autofill:focus, textarea.text_area:-webkit-autofill:focus, input[type=text].text_input:-webkit-autofill:active, input[type=password].text_input:-webkit-autofill:active, input[type=email].text_input:-webkit-autofill:active, .speaker_bio:-webkit-autofill:active, .intl-tel-input input:-webkit-autofill:active, textarea.text_area:-webkit-autofill:active {
  -webkit-text-fill-color: #222;
}
input[type=text].text_input::-webkit-input-placeholder, input[type=password].text_input::-webkit-input-placeholder, input[type=email].text_input::-webkit-input-placeholder, .speaker_bio::-webkit-input-placeholder, .intl-tel-input input::-webkit-input-placeholder, textarea.text_area::-webkit-input-placeholder {
  color: #222;
}
input[type=text].text_input:-moz-placeholder, input[type=password].text_input:-moz-placeholder, input[type=email].text_input:-moz-placeholder, .speaker_bio:-moz-placeholder, .intl-tel-input input:-moz-placeholder, textarea.text_area:-moz-placeholder {
  color: #222;
}
input[type=text].text_input::-moz-placeholder, input[type=password].text_input::-moz-placeholder, input[type=email].text_input::-moz-placeholder, .speaker_bio::-moz-placeholder, .intl-tel-input input::-moz-placeholder, textarea.text_area::-moz-placeholder {
  color: #222;
}
input[type=text].text_input:-ms-input-placeholder, input[type=password].text_input:-ms-input-placeholder, input[type=email].text_input:-ms-input-placeholder, .speaker_bio:-ms-input-placeholder, .intl-tel-input input:-ms-input-placeholder, textarea.text_area:-ms-input-placeholder {
  color: #222;
}
input[type=text].text_input .empty, input[type=password].text_input .empty, input[type=email].text_input .empty, .speaker_bio .empty, .intl-tel-input input .empty, textarea.text_area .empty {
  color: #222;
}
textarea.text_area {
  border: 0;
  resize: none;
}
.select_wrap {
  position: relative;
  display: inline-block;
}
.select_wrap select {
  padding: 5px 0;
  margin: 0;
  border: 0;
  border-bottom: 1px solid #dedede;
  background: transparent;
  color: #7d7d7d;
  outline: none;
  display: inline-block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  width: 100%;
  font-weight: 500;
  font-size: 1.6em;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  -khtml-border-radius: 0;
  -webkit-box-shadow: inset 0 0 1000px #fff;
  -moz-box-shadow: inset 0 0 1000px #fff;
  -ms-box-shadow: inset 0 0 1000px #fff;
  -o-box-shadow: inset 0 0 1000px #fff;
  box-shadow: inset 0 0 1000px #fff;
  width: 100%;
}
.select_wrap select option {
  background-color: #dedede;
}
.select_wrap select.rounded {
  -moz-border-radius: 40px;
  -webkit-border-radius: 40px;
  border-radius: 40px;
  -khtml-border-radius: 40px;
  font-size: 1.5em;
  font-weight: 400;
  line-height: 1.2em;
  padding: 8px 40px;
  -moz-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  height: 35px;
}
.select_wrap select:disabled {
  color: #f6f6f6;
  cursor: default;
}
.select_wrap.select_arrow::after {
  content: '';
  background-position: -2px -67px;
  position: absolute;
  right: 20px;
  top: 17px;
  width: 11px;
  height: 6px;
}
.switcher_wrap h5, .switcher_wrap .base {
  display: inline-block;
}
.switcher_wrap .base {
  width: 30px;
  height: 15px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  -khtml-border-radius: 8px;
  background-color: #dedede;
  border: 1px solid #7d7d7d;
  position: relative;
  -moz-transition: background-color 0.3s ease-in-out;
  -o-transition: background-color 0.3s ease-in-out;
  -webkit-transition: background-color 0.3s ease-in-out;
  transition: background-color 0.3s ease-in-out;
  top: 4px;
}
.switcher_wrap .base .button {
  width: 13px;
  height: 13px;
  -moz-border-radius: 7px;
  -webkit-border-radius: 7px;
  border-radius: 7px;
  -khtml-border-radius: 7px;
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  -moz-transition: left 0.3s ease-in-out;
  -o-transition: left 0.3s ease-in-out;
  -webkit-transition: left 0.3s ease-in-out;
  transition: left 0.3s ease-in-out;
  cursor: pointer;
}
.switcher_wrap .base .button.button_active {
  left: 15px;
}
.switcher_wrap .base.base_active {
  background-color: #fe9d5c;
}
.card_wrap {
  padding: 0;
}
.card_wrap li {
  list-style: none;
  margin-top: 30px;
}
.card_wrap li .content_wrap {
  -webkit-box-shadow: 3px 3px 8px #7d7d7d;
  -moz-box-shadow: 3px 3px 8px #7d7d7d;
  -ms-box-shadow: 3px 3px 8px #7d7d7d;
  -o-box-shadow: 3px 3px 8px #7d7d7d;
  box-shadow: 3px 3px 8px #7d7d7d;
  position: relative;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
}
.card_wrap li .info_wrap {
  -webkit-box-shadow: 3px 3px 8px #7d7d7d;
  -moz-box-shadow: 3px 3px 8px #7d7d7d;
  -ms-box-shadow: 3px 3px 8px #7d7d7d;
  -o-box-shadow: 3px 3px 8px #7d7d7d;
  box-shadow: 3px 3px 8px #7d7d7d;
  position: relative;
  background-color: #fff;
  margin: 20px auto;
}
.card_wrap li .info_wrap .header_wrap {
  color: #fff;
}
.card_wrap li .info_wrap .header_wrap.orange {
  background-color: #fe9d5c;
}
.card_wrap li .info_wrap .header_wrap.blue {
  background-color: #33c6fe;
}
.card_wrap li .info_wrap .header_wrap.red {
  background-color: #e0a0db;
}
.card_wrap li .info_wrap .header_wrap.green {
  background-color: #27d17e;
}
.card_wrap li .info_wrap .header_wrap h3 {
  color: #fff;
}
.card_wrap li .info_wrap .content_wrap .image_wrap {
  margin: 20px auto;
}
.card_wrap li .info_wrap .content_wrap .image_wrap img {
  height: 50px;
}
.card_wrap li .info_wrap .content_wrap h3 a.readmore {
  color: #ff5722;
  text-decoration: none;
  cursor: pointer;
  -moz-transition: color 0.3s ease-in-out;
  -o-transition: color 0.3s ease-in-out;
  -webkit-transition: color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out;
}
.card_wrap li .info_wrap .content_wrap h3 a.readmore:hover {
  color: #de3d0a;
}
.card_wrap li .info_wrap::after {
  content: '';
  position: absolute;
  top: 45%;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
}
.card_wrap li .info_wrap.right::after {
  right: 10px;
  border-left: 10px solid #fe9d5c;
}
.card_wrap li .info_wrap.left::after {
  left: 10px;
  border-right: 10px solid #fe9d5c;
}
.card_wrap li .info_wrap.orange::after {
  border-left-color: #fe9d5c;
  border-right-color: #fe9d5c;
}
.card_wrap li .info_wrap.blue::after {
  border-left-color: #33c6fe;
  border-right-color: #33c6fe;
}
.card_wrap li .info_wrap.red::after {
  border-left-color: #e0a0db;
  border-right-color: #e0a0db;
}
.card_wrap li .info_wrap.green::after {
  border-left-color: #27d17e;
  border-right-color: #27d17e;
}
.card_wrap li .info_wrap .btn_wrap .btn {
  margin-top: 15px;
  float: right;
}
.card_wrap li .info_wrap .orange {
  color: #fe9d5c;
}
.card_wrap li .info_wrap .blue {
  color: #33c6fe;
}
.card_wrap li .info_wrap .red {
  color: #e0a0db;
}
.card_wrap li .info_wrap .green {
  color: #27d17e;
}
.card_wrap li .info_wrap .icon_wrap img {
  width: 100px;
  margin: 15px auto;
}
.card_wrap li .date_wrap {
  height: 100px;
}
.card_wrap li .date_wrap .date {
  padding: 15px;
  color: #fff;
}
.card_wrap li .date_wrap .date.orange {
  background-color: #fe9d5c;
  height: 100%;
}
.card_wrap li .date_wrap .date.blue {
  background-color: #33c6fe;
  height: 100%;
}
.card_wrap li .date_wrap .date.red {
  background-color: #e0a0db;
  height: 100%;
}
.card_wrap li .date_wrap .date.green {
  background-color: #27d17e;
  height: 100%;
}
.card_wrap li .date_wrap .date span {
  vertical-align: -webkit-baseline-middle;
  letter-spacing: 0.08em;
}
.detail_wrap {
  margin-top: 40px;
  background-color: #fff;
}
.detail_wrap .tech_wrap span {
  display: inline-block;
  background-color: #f6f6f6;
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 5px 10px;
}
.input {
  position: relative;
}
.input .info_wrap {
  position: absolute;
  right: 14px;
  bottom: -3px;
  cursor: pointer;
  width: 16px;
  overflow: hidden;
  height: 33px;
  -moz-transition: width 0.3s ease-in;
  -o-transition: width 0.3s ease-in;
  -webkit-transition: width 0.3s ease-in;
  transition: width 0.3s ease-in;
}
.input .info_wrap .info_icon {
  background-position: 0 -49px;
  width: 16px;
  height: 16px;
  position: absolute;
  right: 0;
  bottom: 10px;
}
.input .info_wrap .info_content {
  position: absolute;
  top: -11px;
  right: 21px;
  background-color: #dedede;
  padding: 5px;
  filter: alpha(opacity=0);
  opacity: 0;
  -moz-transition: opacity 0.3s ease-in;
  -o-transition: opacity 0.3s ease-in;
  -webkit-transition: opacity 0.3s ease-in;
  transition: opacity 0.3s ease-in;
}
.input .info_wrap:hover {
  width: 82%;
}
.input .info_wrap:hover .info_content {
  filter: alpha(opacity=100);
  opacity: 1;
}
.required_field {
  position: relative;
}
.required_field::after {
  content: '*';
  color: #ff5722;
  position: absolute;
  right: -11px;
  font-size: 1.8em;
}
.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #222;
  z-index: 10;
  text-align: center;
}
.mask .close_x {
  position: absolute;
  top: 3%;
  right: 2%;
  width: 30px;
  height: 30px;
  cursor: pointer;
  background-position: -140px -26px;
  background-color: #222;
}

/*Main Content*/
.main_content {
  padding: 0;
  margin-top: 50px;
  min-height: 640px;
}
.main_content .progress .bg-app {
  background-color: #fe9d5c;
}
.main_content .progress .bg-blue {
  background-color: #33c6fe;
}
.main_content .progress .bg-red {
  background-color: #ff5722;
}

/*Pagination*/
.pagination {
  display: inline-block;
  width: 100%;
  text-align: center;
}
.pagination li {
  display: inline-block;
}


