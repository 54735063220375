.EnquiryForm {
  /*display: flex;*/
  /*flex-direction: column;*/
  /*align-items: flex-start;*/
  /*justify-content: flex-start;*/
  /*max-width: 500px;*/
  margin: 2.5rem 0;
}

.EnquiryForm > * + * {
  margin-top: 2rem;
}

.EnquiryForm--Label {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
}

.EnquiryForm--Label.required:after{
  content: '*';
  color: red;
  position: absolute;
  right: -20px;
}

.EnquiryForm--Input {
  font-family: inherit;
  font-size: 1.6em;
  flex-grow: 1;
  box-sizing: border-box;
  display: block;
  margin: 0;
  border: 1px solid #bababa;
  padding: 1.5rem 2rem;
  border-radius: 5px;
  line-height: 1.25em;
  transition: border-color 0.2s;
  color: #222;
  resize: none;
}

.EnquiryForm--Select {
  background: none;
  appearance: none;
}

.EnquiryForm--Label.has-arrow:after {
  content: '';
  position: absolute;
  right: 2.5rem;
  top: 50%;
  border-right: 1px solid;
  border-bottom: 1px solid;
  height: 10px;
  width: 10px;
  transform: translateY(-75%) rotate(45deg);
  pointer-events: none;
}

.EnquiryForm--Input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
}

.EnquiryForm--Input:focus {
  outline: none;
  border-color: black;
}

.EnquiryForm--Input[disabled],
.EnquiryForm--SubmitButton[disabled] {
  opacity: 0.4;
  pointer-events: none;
  cursor: progress;
}

.EnquiryForm--Input::placeholder {
  text-transform: none;
  color: #b2b2b2;
}

.EnquiryForm--Alert {
  background: whitesmoke;
  width: 100%;
  text-align: center;
  padding: 2rem;
}

.EnquiryForm .note{ font-size: 0.8em; color: var(--gray); }

.EnquiryForm input[type=submit][disabled] { background-color:#d4d4d4; }
