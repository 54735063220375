.detail_wrap {
  padding-top: 60px;
}
.detail_wrap .detail_content .link-style {
  color: #fe9d5c;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
}
.detail_wrap .detail_content .link-style:hover {
  color: #f9862d;
}
.detail_wrap .detail_content img {
  width: 100%;
}
.detail_wrap .detail_content img.ct_img {
  box-shadow: 2px 2px 6px grey;
}
.detail_wrap .detail_content img.gif_style {
  width: auto;
  box-shadow: none;
}

@media screen and (min-width: 768px) and (max-width: 1024px), screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .detail_wrap .detail_content img.gif_style {
    width: auto;
    box-shadow: none;
  }
}
@media screen and (max-width: 480px), screen and (max-device-width: 480px) {
  .detail_wrap {
    padding-top: 20px;
  }
  .detail_wrap .detail_content img.gif_style {
    width: 100%;
    box-shadow: none;
  }
}

