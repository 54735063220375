.Home {
  padding: 0;
  margin-top: 50px;
  min-height: 640px;
}
.Home .right_wrap {
  position: relative;
  z-index: 8;
}
.Home p {
  line-height: 1.4em;
}
.Home .img_wrap {
  position: relative;
  overflow: hidden;
  z-index: 8;
  margin-bottom: 30px;
}
.Home .img_wrap .name_wrap {
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: -245px;
  left: 5%;
  width: 90%;
  height: 100%;
  transition: top 0.3s ease-in-out;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.Home .img_wrap .name_wrap h4 {
  text-align: center;
  width: 100%;
}
.Home .img_wrap .name_wrap h4 span {
  color: white;
  padding: 5px 10px;
  border: 1px solid white;
  min-width: 130px;
  width: fit-content;
  display: inline-block;
  text-align: center;
}
.Home .img_wrap:hover .name_wrap {
  top: 0;
}
.Home img {
  border: 1px solid darkgrey;
}
@media screen and (min-width: 768px) and (max-width: 1024px), screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .Home .detail_wrap .detail_content .img_wrap:last-of-type img.gif-style {
    width: 100%;
  }
}
@media screen and (max-width: 480px), screen and (max-device-width: 480px) {
  .Home .left_wrap .avatar_wrap {
    margin: 0 auto;
  }
  .Home .right_wrap .btn_wrap button {
    width: 100%;
  }
  .Home .right_wrap p {
    margin-bottom: 30px;
  }
  .Home .img_wrap {
    margin: 0 auto 40px;
  }
  .Home .img_wrap .name_wrap {
    left: 4%;
    width: 92%;
  }
}

