.COVID {
  padding-top: 130px;
  min-height: 100vh;
}

.COVID--Head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 3rem;
}

.COVID--Head .select_wrap select {
  width: auto;
  text-align: right;
}
